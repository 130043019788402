<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="流水号:">
          <el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable></el-input>
        </el-form-item>
				<el-form-item label="姓名:">
				  <el-input 
						v-model.trim="searchForm.username" 
						@keyup.enter.native="seach"
						clearable></el-input>
				</el-form-item>
        <el-form-item label="原教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable></el-input>
        </el-form-item>
        <el-form-item label="新教学点:">
          <el-input
            v-model.trim="searchForm.targetschoolorgname"
						@keyup.enter.native="seach"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="类型:">
          <el-select 
						v-model="searchForm.type" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="selectref"
						clearable>
            <el-option label="转正" value="1"></el-option>
            <el-option label="调岗" value="2"></el-option>
            <el-option label="离职" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
			<el-table-column label="流水号" align="center" width="100" prop="id"/>
      <el-table-column label="姓名" align="center" width="120">
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.username
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="类型" prop="type" align="center">
        <template slot-scope="scope">
          {{ typeVal[scope.row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        label="试用天数"
        prop="trydays"
        align="center"
      ></el-table-column>
      <el-table-column
        label="试用期始"
        prop="trydatefrom"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.trydatefrom | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        label="试用期止"
        prop="trydateto"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.trydateto | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        label="转正申请"
        prop="confirmtime"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.confirmtime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="离职时间" align="center" width="100">
        <template slot-scope="scope">
          {{ scope.row.leavetime | formatDate("YYYY-MM-DD") }}
        </template></el-table-column
      >
      <el-table-column label="调岗时间" align="center" width="100">
        <template slot-scope="scope">
          {{ scope.row.movetime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        label="原教学点"
        prop="schoolorgname"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        label="原工作岗位"
        prop="sourcepositionname"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="新教学点"
        prop="targetschoolorgname"
        align="center"
      ></el-table-column>
      <el-table-column
        label="新工作岗位"
        prop="targetpositionname"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        label="新岗位大级别"
        prop="targetpositiontypenameb"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column label="审核标识" align="center">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 35%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
			:close-on-click-modal='false'
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form :model="dynamicValidateForm" class="form-item-w-50">
				<el-form-item label="流水号:">
					{{dynamicValidateForm.id}}
				</el-form-item>
        <el-form-item label="用户名称:">{{
          dynamicValidateForm.username
        }}</el-form-item>
        <el-form-item label="审核标识:">{{
          approveflag[dynamicValidateForm.approveflag]
        }}</el-form-item>
        <el-form-item label="确认时间:">{{
          dynamicValidateForm.confirmtime | formatDate("YYYY-MM-DD")
        }}</el-form-item>
        <el-form-item label="新岗位系数:">{{
          dynamicValidateForm.targetpositionnumber
        }}</el-form-item>
        <el-form-item label="原分公司名称:">{{
          dynamicValidateForm.companyorgname
        }}</el-form-item>
        <el-form-item label="原教学点名称:">{{
          dynamicValidateForm.schoolorgname
        }}</el-form-item>
        <el-form-item label="原部门名称:">{{
          dynamicValidateForm.departorgname
        }}</el-form-item>
        <el-form-item label="调岗理由:">{{
          dynamicValidateForm.movereason
        }}</el-form-item>
        <el-form-item label="调岗时间:">{{
          dynamicValidateForm.movetime | formatDate("YYYY-MM-DD")
        }}</el-form-item>
        <el-form-item label="入职时间:">{{
          dynamicValidateForm.jointime | formatDate("YYYY-MM-DD")
        }}</el-form-item>
        <el-form-item label="原岗位大级别:">{{
          dynamicValidateForm.sourcepostiontypenameb
        }}</el-form-item>
        <el-form-item label="原岗位小级别:">{{
          dynamicValidateForm.sourcepostiontypenames
        }}</el-form-item>
        <el-form-item label="原岗位系数:">{{
          dynamicValidateForm.sourcepostionnumber
        }}</el-form-item>
        <el-form-item label="原工作岗位名称:">{{
          dynamicValidateForm.sourcepositionname
        }}</el-form-item>
        <el-form-item label="新部门名称:">{{
          dynamicValidateForm.targetdepartorgname
        }}</el-form-item>
        <el-form-item label="新教学点名称:">{{
          dynamicValidateForm.targetschoolorgname
        }}</el-form-item>
        <el-form-item label="新分公司名称:">{{
          dynamicValidateForm.targetcompanyorgname
        }}</el-form-item>
        <el-form-item label="异动类型:">
					<template v-if="dynamicValidateForm.type == 1">转正</template>
					<template v-if="dynamicValidateForm.type == 2">调岗 </template>
					<template v-if="dynamicValidateForm.type == 3">离职 </template>
				</el-form-item>
        <el-form-item label="试用天数:">{{
          dynamicValidateForm.trydays
        }}</el-form-item>
        <el-form-item label="试用期始:">{{
          dynamicValidateForm.trydatefrom
        }}</el-form-item>
        <el-form-item label="试用期止:">{{
          dynamicValidateForm.trydateto
        }}</el-form-item>
        <el-form-item label="离职原因:">{{
          dynamicValidateForm.reason
        }}</el-form-item>
        <el-form-item label="离职时间:">{{
          dynamicValidateForm.leavetime | formatDate("YYYY-MM-DD")
        }}</el-form-item>
        <el-form-item label="审核意见:">{{
          dynamicValidateForm.approvedesc
        }}</el-form-item>
      </el-form>
    </el-dialog>

    <!-- 负责人审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="负责人审批："
			v-dialogDrag
      :visible.sync="showApprovalModal"
			v-if="showApprovalModal"
			:close-on-click-modal='false'
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval">提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  usermove_list,
  usermove_delete,
  usermove_show,
  usermove_approvecheck,
  usermove_approvesave,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {} from "@/request/api/public";
import { _approveflag } from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "usermove",
  components: {},
  props: {},
  data() {
    return {
      approveflag: _approveflag,
      transferDialog: false, //导出框
      typeVal: {
        1: "转正",
        2: "调岗",
        3: "离职",
      },
      itemId: null, //首表格项id
      searchForm: {id:null},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      showDetailModal: false,

      showApprovalModal: false, //审批框
    };
  },
  created() {
    this.searchForm.id = this.$route.params.id;
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  mounted(){
    
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
		enterSelect(e) {
			if(e.keyCode == 13 ) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				id: null,
				schoolorgname: null,
				targetschoolorgname: null,
				username: null,
				type: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: usermove_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectData();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    //查看 api
    btnShowDetail(row) {
      myRequest({
        url: usermove_show,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dialogTitle = "查看";
          this.dynamicValidateForm = res.data.data.vusermove;
          this.dynamicValidateForm.jointime = formatDare(
            this.dynamicValidateForm.jointime,
            "YYYY-MM-DD"
          );
          this.dynamicValidateForm.movetime = formatDare(
            this.dynamicValidateForm.movetime,
            "YYYY-MM-DD"
          );
          this.dynamicValidateForm.leavetime = formatDare(
            this.dynamicValidateForm.leavetime,
            "YYYY-MM-DD"
          );
          this.dynamicValidateForm.trydateto = formatDare(
            this.dynamicValidateForm.trydateto,
            "YYYY-MM-DD"
          );
          this.dynamicValidateForm.trydatefrom = formatDare(
            this.dynamicValidateForm.trydatefrom,
            "YYYY-MM-DD"
          );
          this.showDetailModal = true;
        }
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //负责人审批 click
    btnHeadApproval() {
      selectCheck(this.multipleSelection, "负责人审批", true, () => {
        this.dynamicValidateForm = {};
        this.approvalCheck();
      });
    },
    //审批校验 api
    approvalCheck() {
      myRequest(
        {
          method: "post",
          url: usermove_approvecheck,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          that: this,
          showModal: "showApprovalModal",
        }
      );
    },
    //保存负责人审批 api
    headApproval() {
      myRequest(
        {
          method: "post",
          url: usermove_approvesave,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
              passflag: this.dynamicValidateForm.passflag,
              approvedesc: this.dynamicValidateForm.approvedesc,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,

          modal: "showApprovalModal",
        }
      );
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: usermove_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },

  
};
</script>

<style  lang="scss">
</style>